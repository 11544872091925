import { useSubmit } from '@remix-run/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Body, Heading } from '#app/components/typography'
import { Button } from '#app/components/ui/button'
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTrigger,
} from '#app/components/ui/dialog'
import { Label } from '#app/components/ui/label'
import { RadioGroup, RadioGroupItem } from '#app/components/ui/radio-group'
import {
	getFullLanguageName,
	getLanguageShortHand,
} from '#app/utils/localisation/lokalisation-utilities'
import { cn } from '#app/utils/misc'
import { Icon } from '../../components/ui/icon'

const LanguageModal = () => {
	let { t, i18n } = useTranslation('common')
	const [currentSelection, setCurrentSelection] = useState(
		getFullLanguageName(i18n.language),
	)
	const languages: { name: string }[] = [{ name: 'English' }, { name: 'Dansk' }]
	const submit = useSubmit()

	const handleSubmit = async (formData: FormData) => {
		try {
			await submit(formData, {
				method: 'POST',
				action: '/resources/language',
			})
		} catch (error) {
			console.log(error)
			console.error(error)
		}
	}

	const handleChangeLanguage = (language: string) => {
		// Change the language using i18n
		i18n.changeLanguage(getLanguageShortHand(language))

		// Get the current URL parameters
		const urlParams = new URLSearchParams(window.location.search)

		// Define the parameters you want to handle
		const paramsToCheck = [
			'color',
			'brand',
			'materials',
			'size',
			'sortingOrder',
		]

		// Check if the necessary params are present and clean them up if necessary
		paramsToCheck.forEach(param => {
			if (urlParams.has(param)) {
				urlParams.delete(param) // Remove the parameter from the URL
			}
		})

		// Add the language parameter to the form data
		const formData = new FormData()
		formData.append('language', getLanguageShortHand(language))

		// Update the URL without reloading the page
		const newUrl = `${window.location.pathname}?${urlParams.toString()}`
		window.history.replaceState({}, '', newUrl)

		// Handle the form submission
		handleSubmit(formData)
	}

	return (
		<Dialog>
			<DialogTrigger>
				<div className={cn('group cursor-pointer flex-row')}>
					<div className="md:flex">
						<Icon
							name={t('flag')}
							className="self-middle mb-[3px] h-[16px] w-[16px]"
						/>
						<div className="ml-2 border-b border-b-transparent group-hover:border-black-90 group-focus:border-mint-green-50">
							<Body as="s" body={t('language')} />
						</div>
					</div>
				</div>
			</DialogTrigger>
			<DialogContent className="rounded-3xl sm:rounded-3xl" key="content">
				<DialogHeader key="header">
					<Heading as="h7">{t('change_language')}</Heading>
					<Body as="m" body={t('change_language_body')} />
				</DialogHeader>
				<RadioGroup>
					{languages.map(({ name }) => (
						<div className="flex items-center space-x-2" key={name}>
							<RadioGroupItem
								className="border-0 bg-white text-black-90 ring-2 ring-beige-50 aria-checked:ring-mint-green-80p"
								value={name}
								id={name}
								checked={currentSelection === name} // Set checked based on current selection
								onClick={() => setCurrentSelection(name)}
							/>
							<Label htmlFor={name}>{name}</Label>
						</div>
					))}
				</RadioGroup>
				<DialogFooter
					className="justify-between sm:justify-between"
					key="footer"
				>
					<DialogClose asChild className="w-1/2" key="cancel">
						<Button
							variant="secondary"
							size="primary"
							className="w-full text-lg"
						>
							{t('cancel')}
						</Button>
					</DialogClose>
					<DialogClose asChild className="w-1/2" key="change">
						<Button
							variant="primary"
							size="primary"
							onClick={() => handleChangeLanguage(currentSelection)}
							className="text-lg"
						>
							{t('change_language')}
						</Button>
					</DialogClose>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}

export default LanguageModal
